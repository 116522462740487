<template>
  <a-drawer
    title="发起结算"
    width="700"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 项目 -->
      <a-form-item label="项目">
        {{ detail.project_name }}
      </a-form-item>
      <!-- 订单日期 -->
      <a-form-item label="订单日期">
        {{ `${detail.start_time} 至 ${detail.end_time}` }}
      </a-form-item>
      <!-- 可结算订单总数 -->
      <a-form-item label="可结算订单总数">
        {{ detail.order_num || 0 }}个
      </a-form-item>
      <!-- 订单总金额 -->
      <a-form-item label="订单总金额">
        {{ $pub.KEEP_NUMBER_DECIMAL(detail.order_amount / 100, 2, false, true) }}元
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <div class="footer-view">
        <div style="flex: 1;"></div>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="touchSubmit">确定</a-button>
      </div>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { settlement } from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
let detail = ref({})
// 表单参数
const formState = reactive({
})

// 显示
function showDrawer (data) {
  // 获取详情
  detail.value = data
  // 显示
  visible.value = true
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
    resetFields()
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    let params = {
      project_id: detail.value.project_id,
      start_time: detail.value.start_time,
      end_time: detail.value.end_time
    }
    settlement(params).then(res => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0) {
        message.success('结算成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    })
  }).catch((err) => {
    console.log(err)
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.footer-view {
  display: flex;
  align-items: center;
}
.footer-hint {
  color: #5A5A5A;
}
</style>